import Service from "@/helpers/service";

export default {
  get(page, params) {
    return Service().get("ABCCriteria/ServiceLevel?page="+page, {params});
  },
  update_service_level(params) {
    return Service().post("ABCCriteria/ServiceLevel/update", params);
  },
  create_service_level(params) {
    return Service().post("ABCCriteria/ServiceLevel/create", params);
  },
  delete_service_level(id) {
    return Service().post("ABCCriteria/ServiceLevel/delete/" + id);
  },
};
